import Application from 'modules/application';
import SEO from 'modules/seo';
import React from 'react';
import Article from 'routes/article';

const Page = ({ pageContext }) => {
  const {
    color,
    slug,
    content,
    customAuthor,
    author,
    featuredImage,
    publicationDate,
    title,
  } = pageContext

  return (
    <Application>
      <SEO
        description={`Read ${title} on CoBuildIt.`}
        title={title}
        image={featuredImage}
        slug={slug}
      />
      <Article
        slug={slug}
        color={color}
        title={title}
        content={content}
        customAuthor={customAuthor}
        author={author}
        image={featuredImage}
        publishedAt={publicationDate}
      />
    </Application>
  )
}

export default Page
