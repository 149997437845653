import React from 'react';

import css from './style.module.scss';

const Footer: React.FC = () => (
  <div className={css.container}>
    <div className={css.content}>
      © {new Date().getFullYear()}{" "}
      <a className={css.anchor} href="https://cobuildit.com">
        CobuildIt
      </a>
    </div>
  </div>
)

export default Footer
