import Utils from 'core/utils';
import Facebook from 'images/facebook.svg';
import LinkIcon from 'images/link.svg';
import Twitter from 'images/twitter.svg';
import React, { useState } from 'react';

import css from './style.module.scss';

type ShareProps = {
  title?: string
  description?: string
  slug?: string
}

const Share: React.FC<ShareProps> = ({ title, description, slug }) => {
  const [linkCopiedVisible, setLinkCopiedVisible] = useState(false)

  const url = Utils.getFullUrlFromSlug(slug)
  const facebookURL = `https://www.facebook.com/sharer.php?u=${url}`
  const twitterURL = `https://twitter.com/intent/tweet?url=${url}&text=${title}`

  const handleCopyToClipboard = async () => {
    await Utils.copyToClipboard(url)
    setLinkCopiedVisible(true)

    setTimeout(() => setLinkCopiedVisible(false), 2 * 1000)
  }

  return (
    <div className={css.container}>
      {linkCopiedVisible && <span className={css.copied}>Link copied</span>}
      <a href={twitterURL} target="_blank" className={css.link}>
        <img src={Twitter} alt="Twitter" className={css.image} />
      </a>
      <a href={facebookURL} target="_blank" className={css.link}>
        <img src={Facebook} alt="FB" className={css.image} />
      </a>
      <span onClick={handleCopyToClipboard} className={css.link}>
        <img src={LinkIcon} alt="Copy link" className={css.image} />
      </span>
    </div>
  )
}

export default Share
