import Navigation from 'modules/navigation';
import React from 'react';

import Content from './content';
import FeaturedImage from './featured-image';
import Footer from './footer';
import Header from './header';

type ArticleProps = {
  slug?: string
  color?: string
  title?: string
  content?: string
  customAuthor?: string
  author?: BlogPostAuthorType
  image?: string
  publishedAt?: string
}

const Article: React.FC<ArticleProps> = props => {
  const { content, image } = props

  return (
    <>
      <Navigation />
      <Header {...props} />
      <FeaturedImage src={image} />
      <Content html={content} />
      <Footer />
    </>
  )
}

export default Article
