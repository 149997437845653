import React from 'react';

import css from './style.module.scss';

type ContentProps = {
  html?: string
}

const Content: React.FC<ContentProps> = ({ html }) => (
  <div className={css.container}>
    <div
      className={css.content}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  </div>
)

export default Content
