import React from 'react';

import css from './style.module.scss';

type FeaturedImageProps = {
  src?: string
}

const FeaturedImage: React.FC<FeaturedImageProps> = ({ src }) => (
  <div className={css.container}>
    <div className={css.content}>
      <img className={css.image} src={src} alt="Featured Image" />
    </div>
  </div>
)

export default FeaturedImage
