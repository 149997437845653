import Share from 'modules/share';
import React from 'react';

import TitleCard from '../../../modules/title-card';
import css from './style.module.scss';

type HeaderProps = {
  slug?: string
  color?: string
  title?: string
  content?: string
  customAuthor?: string
  author?: BlogPostAuthorType
  image?: string
  publishedAt?: string
}

const Header: React.FC<HeaderProps> = props => {
  const { title, slug } = props

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div className={css.leftcol}>
          <TitleCard {...props} />
        </div>
        <div className={css.rightcol}>
          <Share title={title} slug={slug} />
        </div>
      </div>
    </div>
  )
}

export default Header
